import React,{useState,useEffect} from 'react';
import "./MobileAboutBrands.css";
import { useSelector } from "react-redux";
import { selectProductDetails } from "../../features/productDesc/productDescSlice";
import Funn from '../../components/ProductDescription/BrandsDetails/Funn';
import Cratoni from '../../components/ProductDescription/BrandsDetails/Cratoni';
import Moon from '../../components/ProductDescription/BrandsDetails/Moon';
import Granite from '../../components/ProductDescription/BrandsDetails/Granite';
import Proride from '../../components/ProductDescription/BrandsDetails/Proride';
import BikePro from '../../components/ProductDescription/BrandsDetails/BikePro';
import Dass from '../../components/ProductDescription/BrandsDetails/Dass';
import SovereignSaddles from '../../components/ProductDescription/BrandsDetails/SovereignSaddles';
import MasterChains from '../../components/ProductDescription/BrandsDetails/MasterChains';

function MobileAboutBrands() {
  const productSelect = useSelector(selectProductDetails);
  const [componentToRender, setComponentToRender] = useState(null);
  useEffect(() => {
    if (
      productSelect &&
      productSelect.products &&
      productSelect.products.length > 0
    ) {
      const manufacturer = productSelect.products[0].Manufacturer;

      switch (manufacturer) {
        case "FUNN":
          setComponentToRender(<Funn />);
          break;
        case "CRATONI":
          setComponentToRender(<Cratoni />);
          break;
        case "GRANITE":
          setComponentToRender(<Granite />);
          break;
        case "MOON SPORTS":
          setComponentToRender(<Moon />);
          break;
        case "PRORIDE":
          setComponentToRender(<Proride />);
          break;
        case "BIKEPRO":
          setComponentToRender(<BikePro />);
          break;
        case "DASS":
          setComponentToRender(<Dass />);
          break;
        case "SOVEREIGN":
          setComponentToRender(<SovereignSaddles />);
          break;
        case "MASTER":
          setComponentToRender(<MasterChains />);
          break;
        default:
          setComponentToRender(null);
      }
    }
  }, [productSelect]);
  return (
    <div className="mobile-about_containers">
      {/* <h3 className="about_heading">About Brands</h3> */}
      {componentToRender}
    </div>
  )
}

export default MobileAboutBrands